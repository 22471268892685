const locale = {
  EMAIL_VALIDATION: 'You must enter a valid email',
  EMAIL_REQUIRED: 'You must enter a email',
  PASSWORD_VALIDATION: 'Please enter your password.',
  PASSWORD_TOO_SHORT_VALIDATION: 'Password is too short - must be at least 4 chars.',
  SIGN_IN: 'Sign In',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  REMEMBER_ME: 'Remember me',
  FORGOT_PASSWORD: 'Forgot password?'
};

export default locale;
