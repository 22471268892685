import { lazy } from 'react';

const AssetsDashboardApp = lazy(() => import('./AssetsDashboardApp'));

const AssetsDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/analytics',
      element: <AssetsDashboardApp />,
    },
  ],
};

export default AssetsDashboardAppConfig;
