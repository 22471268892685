const sandlight = {
  50: '#fcfbf8',
  100: '#f9f7f1',
  200: '#f7f3eb',
  300: '#f4efe4',
  400: '#f2ebde',
  500: '#efe7d7',
  600: '#ece3d0',
  700: '#eadfca',
  800: '#e7dbc3',
  900: '#e5d7bd',
  contrastDefaultColor: 'dark',
};

export default sandlight;
