const locale = {
  EMAIL_VALIDATION: 'Du måste ange en giltig e-postadress',
  EMAIL_REQUIRED: 'Du måste ange en e-post',
  PASSWORD_VALIDATION: 'Vänligen ange ditt lösenord.',
  PASSWORD_TOO_SHORT_VALIDATION: 'Lösenordet är för kort - måste bestå av minst 4 tecken.',
  SIGN_IN: 'Logga in',
  EMAIL: 'E-post',
  PASSWORD: 'Lösenord',
  REMEMBER_ME: 'Kom ihåg mig',
  FORGOT_PASSWORD: 'Glömt ditt lösenord?'
};

export default locale;
