/**
 * Authorization Roles
 */
const authRoles = {
  Admin: ['Admin'],
  Reseller: ['Reseller'],
  CompanyManager: ['CompanyManager'],
  DepartmentManager: ['DepartmentManager'],
  Employee: ['Employee'],
  OnlyGuest: [],
};

export default authRoles;
