import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { selectCompany } from 'app/store/companySwitcherSlice';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

function CompanySwitcher() {
  const { t } = useTranslation();
  const selectedCompany = useSelector((state) => state.companySwitcher.selectedCompany);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isAnalyticsRoute = location.pathname === '/dashboards/analytics';

  /**
   * Axios HTTP Request defaults
   */
  axios.defaults.headers.common.companyId = selectedCompany;

  const companies = useSelector((state) => state.companySwitcher.companies);
  const error = useSelector((state) => state.companySwitcher.error);

  const handleCompanyChange = (event) => {
    dispatch(selectCompany(event.target.value));
    if (!isAnalyticsRoute) navigate('/dashboards/analytics');
    else window.location.reload();
  };

  const AllowedToSeeCompanySwitcher = () => {
    const allowedRoles = ['Admin', 'Reseller'];
    return allowedRoles.includes(user.role);
  }

  if(!AllowedToSeeCompanySwitcher()) return null;

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        { (!selectedCompany || companies.length == 0 || !companies) ?
          <CircularProgress size={25} className='mr-24' /> : (
          <TextField
            id="outlined-select-company"
            select
            required
            label={t('COMPANY')}
            value={selectedCompany}
            size="small"
            onChange={handleCompanyChange}
          >
          {companies.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {`${option.name} ${(option.companyCode) ? '('+option.companyCode+')' : ''}`}
            </MenuItem>
          ))}
        </TextField>
        )}
      </div>
    </Box>
  );
}

export default CompanySwitcher;
