import AppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';

function FooterLayout1(props) {
  const { companyThemeConfig, isLoading } = props;
  const footerTheme = useSelector(selectFooterTheme);
  const [companyTheme, setCompanyTheme] = useState(footerTheme);

  useEffect(() => {
    if (!footerTheme || !Object.keys(companyThemeConfig).length === 0) return;

    const { backgroundHex, textPrimaryRgb, textSecondaryRgb, textDisabledRgb } =
      companyThemeConfig;

    const updatedTheme = {
      ...footerTheme,
      palette: {
        ...footerTheme.palette,
        background: {
          default: 'transparent',
          paper: 'transparent',
        },
        text: {
          primary: textPrimaryRgb,
          secondary: textSecondaryRgb,
          disabled: textDisabledRgb,
        },
      },
    };
    setCompanyTheme(updatedTheme);
  }, [companyThemeConfig]);

  return (
    companyTheme &&
    !isLoading && (
      <ThemeProvider theme={companyTheme}>
        <AppBar
          id="fuse-footer"
          className={clsx('relative z-20 shadow-md', props.className)}
          color="default"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? companyTheme.palette.background.paper
                : companyTheme.palette.background.default,
          }}
        >
          <Toolbar className="min-h-48 md:min-h-64 px-8 sm:px-12 py-0 flex items-center overflow-x-auto" />
        </AppBar>
      </ThemeProvider>
    )
  );
}

export default memo(FooterLayout1);
