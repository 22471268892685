import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import i18next from 'i18next';
import se from './i18n/se';
import en from './i18n/en';

const Asset = lazy(() => import('./asset/Asset'));
const Assets = lazy(() => import('./assets/Assets'));
const AssetsUser = lazy(() => import('./assets-user/AssetsUser'));
const AssetsStation = lazy(() => import('./assets-station/AssetsStation'));
const AssetsVehicle = lazy(() => import('./assets-vehicle/AssetsVehicle'));
const Department = lazy(() => import('./department/Department'));
const Departments = lazy(() => import('./departments/Departments'));
const Reseller = lazy(() => import('./reseller/Reseller'));
const Resellers = lazy(() => import('./resellers/Resellers'));
const Company = lazy(() => import('./company/Company'));
const Companies = lazy(() => import('./companies/Companies'));
const Station = lazy(() => import('./station/Station'));
const Stations = lazy(() => import('./stations/Stations'));
const AssetCategory = lazy(() => import('./asset-category/AssetCategory'));
const AssetCategories = lazy(() =>
  import('./asset-categories/AssetCategories')
);
const InventoryCategory = lazy(() =>
  import('./inventory-category/InventoryCategory')
);
const InventoryCategories = lazy(() =>
  import('./inventory-categories/InventoryCategories')
);
const Inventory = lazy(() => import('./inventory/Inventory'));
const Inventories = lazy(() => import('./inventories/Inventories'));
const CompanyUser = lazy(() => import('./companyUser/CompanyUser'));
const ResellerUser = lazy(() => import('./resellerUser/ResellerUser'));
const AdminUser = lazy(() => import('./adminUser/AdminUser'));
const CompanyUsers = lazy(() => import('./companyUsers/CompanyUsers'));
const ResellerUsers = lazy(() => import('./resellerUsers/ResellerUsers'));
const AdminUsers = lazy(() => import('./adminUsers/AdminUsers'));
const Vehicle = lazy(() => import('./vehicle/Vehicle'));
const Vehicles = lazy(() => import('./vehicles/Vehicles'));

i18next.addResourceBundle('en', 'ecommerce', en);
i18next.addResourceBundle('se', 'ecommerce', se);

const ECommerceAppConfig = {
  settings: {
    layout: {},
  },
  auth: [
    'Admin',
    'CompanyManager',
    'DepartmentManager',
    'Reseller',
    'Employee',
  ],
  routes: [
    {
      path: 'asset',
      element: <Assets />,
    },
    {
      path: 'assets/:assetId',
      element: <Asset />,
    },
    {
      path: 'company/:companyId/assets/:assetId',
      element: <Asset />,
    },
    {
      path: 'companyUser/:userId/assets/:assetId',
      element: <Asset />,
    },
    {
      path: 'companyUser/:userId/:assetId',
      element: <Asset />,
    },
    {
      path: 'station/:stationId/assets/:assetId',
      element: <Asset />,
    },
    {
      path: 'vehicle/:vehicleId/assets/:assetId',
      element: <Asset />,
    },
    {
      path: 'station/:stationId/:assetId',
      element: <Asset />,
    },
    {
      path: 'vehicle/:vehicleId/:assetId',
      element: <Asset />,
    },
    {
      path: 'asset/filter/:filterType/:filterKey',
      element: <Assets />,
    },
    {
      path: 'companyUser/:userId/assets/new-assigns',
      element: <AssetsUser />,
    },
    {
      path: 'station/:stationId/assets/new-assigns',
      element: <AssetsStation />,
    },
    {
      path: 'vehicle/:vehicleId/assets/new-assigns',
      element: <AssetsVehicle />,
    },
    {
      path: 'asset-categories',
      element: <AssetCategories />,
    },
    {
      path: 'asset-categories/:assetCategoryId/*',
      element: <AssetCategory />,
    },
    {
      path: 'inventory-categories',
      element: <InventoryCategories />,
    },
    {
      path: 'inventory-categories/:inventoryCategoryId/*',
      element: <InventoryCategory />,
    },
    {
      path: 'inventories',
      element: <Inventories />,
    },
    {
      path: 'inventories/:inventoryId/*',
      element: <Inventory />,
    },
    {
      path: 'departments',
      element: <Departments />,
    },
    {
      path: 'department/:departmentId',
      element: <Department />,
    },
    {
      path: 'company/:companyId/department/:departmentId',
      element: <Department />,
    },
    {
      path: 'companies',
      element: <Companies />,
    },
    {
      path: 'company/:companyId',
      element: <Company />,
    },
    {
      path: 'assets',
      element: <Navigate to="assets" />,
    },
    {
      path: 'resellers',
      element: <Resellers />,
    },
    {
      path: 'reseller/:resellerId',
      element: <Reseller />,
    },
    {
      path: 'stations',
      element: <Stations />,
    },
    {
      path: 'station/:stationId',
      element: <Station />,
    },
    {
      path: 'vehicles',
      element: <Vehicles />,
    },
    {
      path: 'vehicle/:vehicleId',
      element: <Vehicle />,
    },
    {
      path: 'company/:companyId/station/:stationId',
      element: <Station />,
    },
    {
      path: 'companyUsers',
      element: <CompanyUsers />,
    },
    {
      path: 'companyUser/:userId',
      element: <CompanyUser />,
    },
    {
      path: 'company/:companyId/user/:userId',
      element: <CompanyUser />,
    },
    {
      path: 'resellerUsers',
      element: <ResellerUsers />,
    },
    {
      path: 'resellerUser/:userId',
      element: <ResellerUser />,
    },
    {
      path: 'adminUsers',
      element: <AdminUsers />,
    },
    {
      path: 'adminUser/:userId',
      element: <AdminUser />,
    },
  ],
};

export default ECommerceAppConfig;
