const locale = {
  // Page Headers
  ASSETS: "Hårdvara",
  ASSET: "Hårdvara",
  ASSET_CATEGORIES: "Kategorier",
  ASSET_CATEGORY: "Kategori",
  ASSET_DASHBOARD: "Tillgångsöversikt",
  STATIONS: "Platser",
  STATION: "Plats",
  USER: "Användare",
  COMPANYUSERS: "Användare",
  RESELLERUSERS: "Administratörer Dialect",
  ADMINUSERS: "Administratörer",
  COMPANYUSER: "Användare",
  RESELLERUSER: "Administratör Dialect",
  ADMINUSER: "Administratör",
  COMPANIES: "Kunder",
  COMPANY: "Kund",
  DEPARTMENTS: "Avdelningar",
  DEPARTMENT: "Avdelning",
  RESELLERS: "Företagscenter",
  RESELLER: "Företagscenter",
  INVENTORY_CATEGORIES: "Artikelkategorier",
  INVENTORY_CATEGORY: "Artikelkategori",

  // Tab Headers
  BASIC_INFO: "Information",
  ASSET_IMAGES: "Bilder",
  ASSET_ATTACHMENTS: "Bilagor",
  ASSET_ASSIGN: "Tilldela",
  ASSET_ASSIGN_HISTORY: "Historik",

  // Shared
  ADD: "Lägg till",
  NAME: "Namn",
  DETAILS: "Detaljer",
  ROWS_PER_PAGE: "Rader per sida",
  REMOVE: "Ta bort",
  SAVE: "Spara",
  CHOOSE: "Välj",
  ASSIGN: "Tilldela",
  ASSIGN_TYPE: "Tilldelningstyp",
  SUCCESS_SAVE: "Sparad",
  ERROR_SAVE: "Ett fel uppstod, försök igen senare",
  CREATED: "Skapad",
  UPDATED: "Uppdaterad",
  ACTIVE: "Aktiv",
  DEPARTMENT_IS_REQUIRED: "Avdelning krävs",
  CLEAR_FILTERS: "Rensa filter",
  ASSIGN_TO_USER: "Tilldela till användare",
  ASSIGN_TO_STATION: "Tilldela till plats",
  ASSIGN_TO_VEHICLE: "Tilldela till fordon",
  REASSIGN_TO_USER: "Tilldela om tillgång",
  PAGE: "Sida",
  OF: "av",
  ALL_OPTIONS: "Alla",
  UNASSIGN: "Avbryt tilldelning",
  UNASSIGNED: "Otilldelad",
  TOTAL: "Totalt",
  NAME_REQUIRED: "Namn krävs",
  CATEGORY_REQUIRED: "Kategori krävs",
  PURCHASE_DATE_REQUIRED: "Inköpsdatum krävs",
  MISSING_REQUIRED_FIELDS:
    "Obligatoriska fält saknas. Kontrollera fälten markerade med * ( Namn, kategori )",

  // Dashboard
  ASSET_DASHBOARD_ZERO_VAL: "Visa nollvärden",
  ASSET_DASHBOARD_OVERVIEW: "Översikt av Tillgångar",
  ASSET_DASHBOARD_CATEGORY: "Tillgångar per kategori",
  ASSET_DASHBOARD_PIECES: " st.",
  ASSET_DASHBOARD_TOTAL_ASSETS: "Totalt antal tillgångar",
  ASSET_DASHBOARD_INUSE_ASSETS: "Tillgångar i bruk",
  ASSET_DASHBOARD_AVAL_ASSETS: "Tillgängliga tillgångar",

  // Assets
  SEARCH_TITLE_ASSETS: "Sök hårdvara",
  NO_ASSETS: "Det finns ingen hårdvara",
  SAVE_ASSET_BEFORE_ASSIGN: "Spara enheten innan du tilldelar den",
  ASSET_DETAIL: "Detaljer",
  INSTALLATION_IMAGE: "Installationsbild",
  ASSET_IMAGE: "Produktbild",
  ARTICLE_NUMBER: "Artikelnummer",
  PURCHASE_DATE: "Inköpsdatum",
  EXPIRE_DATE: "Utgångsdatum",
  ALTERNATE_DESCRIPTION: "Beskrivning",
  CATEGORY: "Kategori",
  ASSIGNEE: "Tilldelad",
  PRICE: "Pris",
  IMEI: "IMEI-nummer",
  SERIAL_NUMBER: "Serienummer",
  SERIALIMEI_NUMBER: "Serie/IMEI-nummer",
  NODE_NUMBER: "Nodnummer",
  AVAILABLE: "Tillgänglig",
  ASSIGN_TYPE_REQUIRED: "Tilldelningstyp krävs",
  USER_REQUIRED: "Användare är obligatorisk",
  STATION_REQUIRED: "Station är obligatorisk",
  VEHICLE_REQUIRED: "Fordon är obligatorisk",
  GO_TO_ASSETS_PAGE: "Gå till hårdvara",
  ASSIGNED_TO: "Tilldelad till",
  ASSIGNED_DATE: "Tilldelningsdatum",
  ASSIGN_USER_STATION: "Tilldela till en användare eller station",
  ESTIMETED_TRADEIN: "Uppskattat inbytesvärde",
  SUPPORT_LINK: "Supportlänk",
  STATUS: "Status",
  PUBLIC_NOTES: "Offentliga anteckningar",
  ADMIN_NOTES: "Administratörsanteckningar",
  NEW_ASSET: "Ny hårdvara",
  ASSET_ASSIGN_MSG_CONFIRMATION:
    "Är du säker på att du vill tilldela enheten {{assetName}}?",
  ASSET_UNASSIGN_MSG_CONFIRMATION:
    "Är du säker på att du vill avbryta tilldelningen av enheten {{assetName}}?",
  ASSETS_ASSIGN_MSG_CONFIRMATION:
    "Är du säker på att du vill tilldela dessa enheter?",
  ASSIGN_TO_THIS_USER: "Tilldela ny enhet till denna Användare",
  ASSIGN_ASSET_USER: "Tilldela en enhet till Användare",
  ASSIGN_TO_THIS_STATION: "Tilldela ny enhet till den här platsen",
  ASSIGN_TO_THIS_VEHICLE: "Tilldela ny enhet till den här fordon",
  ASSIGN_ASSET_STATION: "Tilldela en enhet till platsen",
  ASSIGN_ASSET_VEHICLE: "Tilldela en enhet till fordon",
  ASSET_NOT_FOUND: "Spara enheten innan tilldelning",
  ASSET_NOT_SAVED_ATTACHMENTS: "Spara enheten innan du laddar upp en bilaga",
  ASSET_REMOVE_MSG_CONFIRMATION:
    "Är du säker på att du vill ta bort den här enheten?",
  ASSET_WITH_NO_ATTACHMENTS: "Det finns inga bilagor",
  ASSET_DUPLICATED_ATTACHMENT: "Enheten har redan en bilaga med samma namn",

  // Assets history
  ASSET_ASSIGN_HISTORY_NO_HISTORY: "Ingen historik för denna enhet.",
  ASSET_HISTORY_CREATE: "Enhet skapad",
  ASSET_HISTORY_UPDATE: "Enhet uppdaterad",
  ASSET_HISTORY_DELETE: "Enhet raderad",
  ASSET_HISTORY_ASSIGN: "Enhet tilldelad till ",
  ASSET_HISTORY_UNASSIGN: "Enhet oallokerad",
  ASSET_HISTORY_ASSETIMAGE: "Produktbild uppdaterad",
  ASSET_HISTORY_ASSETISNTIMAGE: "Installationsbild uppdaterad",
  ASSET_HISTORY_BY: "av",
  ASSET_HISTORY_AT: "vid",

  // Asset Status
  IN_USE: "Används",
  UNDER_REPAIR: "Under reparation",
  FREE: "Fri",

  // Station
  NEW_STATION: "Ny plats",
  STATION_DETAIL: "Detaljer",
  DEPARTMENT_SELECT_VALIDATION: "Du måste välja en avdelning",
  NO_STATION_FOUND: "Det finns ingen sådan plats",
  GO_TO_STATION_PAGE: "Gå till platser",
  STATION_NAME_VALIDATION: "Du måste ange ett namn",
  SEARCH_STATIONS: "Sök platser",
  NO_STATIONS_FOUND: "Det finns inga platser",
  ASSIGNED_ASSETS_COUNT: "Tilldelade enheter",

  // Vehicle
  VEHICLES: "Fordon",
  VEHICLE: "Fordon",
  NEW_VEHICLE: "Ny fordon",
  VEHICLE_DETAIL: "Detaljer",
  SEARCH_VEHICLES: "Sök fordon",
  NO_VEHICLES_FOUND: "Det finns inga fordon",
  VEHICLE_NAME_VALIDATION: "Du måste ange ett namn",
  NO_VEHICLE_FOUND: "Det finns ingen sådan fordon",
  GO_TO_VEHICLE_PAGE: "Gå till fordon",
  VEHICLE_TYPE: "Fordonstyp",
  VEHICLE_MODEL: "Modell",
  CAR: "Bil",
  TRAILER: "Släpvagn",
  TRUCK: "Lastbil",
  BUS: "Buss",
  MOTORCYCLE: "Motorcykel",
  BICYCLE: "Cykel",

  // Asset Categories
  SEARCH_ASSETS_CATEGORIES: "Sök kategorier",
  NO_ASSETS_CATEGORIES: "Det finns inga kategorier",
  CATEGORY_NAME_VALIDATION: "Du måste ange ett kategorinamn",
  NO_ASSET_CATEGORY: "Det finns ingen sådan kategori",
  GO_TO_ASSET_CATEGORY: "Gå till kategorier",
  NEW_ASSET_CATEGORY: "Ny kategori",
  ASSET_CATEGORY_DETAIL: "Detaljer",
  IS_REPORT_VISIBLE: "Visa i rapport",
  ARTICLE_TYPE: "Artikeltyp",

  // Inventory Category
  SEARCH_INVENTORY_CATEGORIES: "Sök kategorier",
  NO_INVENTORY_CATEGORIES: "Det finns inga kategorier",
  NO_INVENTORY_CATEGORY: "Det finns ingen sådan kategori",
  GO_TO_INVENTORY_CATEGORY: "Gå till artikelkategorier",
  INVENTORY_CATEGORY_DETAIL: "Detaljer",
  CALCULATION_TYPE: "Typ av beräkning",

  // Inventory
  NO_INVENTORY: "Artikel hittades inte",
  GO_TO_INVENTORIES: "Gå till Artiklar",
  INVENTORY: "Artiklar",
  TITLE: "Titel",
  SEARCH_INVENTORY: "Sök artiklar",
  DESCRIPTION: "Beskrivning",
  SUPPLIER: "Leverantör",
  BASE_PRICE: "Grundpris",
  PURCHASE_PRICE: "Inköpspris",
  STOCK_PRICE: "Lagerpris",
  NO_INVENTORIES: "Det finns inga artiklar",

  // User
  USER_NAME_VALIDATION: "Du måste ange ett användarnamn för användaren",
  USER_EMAIL_VALIDATION: "Du måste ange en e-postadress för användaren",
  NO_USER_FOUND: "Det finns ingen sådan användare",
  ROLE: "Roll",
  GO_TO_USER_PAGE: "Gå till användarsidan",
  USER_DETAIL: "Detaljer",
  USER_MSG_CONFIRMATION: "Är du säker på att du vill inaktivera?",
  CANCEL: "Avbryt",
  CONFIRM: "Bekräfta",
  USERNAME: "Användarnamn",
  PHONE_NUMBER: "Telefonnummer",
  EMAIL: "E-post",
  SHOW_ACTIVE_USERS: "Visa aktiva användare",
  SHOW_INACTIVE_USERS: "Visa inaktiva användare",
  SEARCH_USERS: "Sök användare",
  NO_USERS_FOUND: "Det finns inga användare",
  JOB_TITLE: "Arbetstitel",
  COST_CENTER: "Kostnadsställe",
  EMPLOYEE_NUMBER: "Anställdsnummer",
  DATE_EMPLOYEEMENT_START: "Startdatum för anställning",
  DATE_EMPLOYEEMENT_END: "Slutdatum för anställning",
  INVITE_STATUS: " Inbjudningsstatus",
  MY_PROFILE: "Min Profil",
  SIGN_OUT: "Logga ut",
  NEW_USER: "Ny användare",
  DEACTIVATE: "Avaktivera",
  INVITE: "Bjud in",
  USER_INVITE_CONFIRM_MSG:
    "Är du säker på att du vill bjuda in denna användare?",
  USER_INVITED: "Användaren inbjuden framgångsrikt.",
  USER_ACTIVATED_ERROR: "Aktivering av användare misslyckades.",
  USER_ACTIVATED_SUCCESS: "Användare aktiverad framgångsrikt.",
  USERS: "Användare",

  // Invite Status
  INV_STATUS_NOTSENT: "Inte skickat",
  INV_STATUS_PENDING: "Väntar",
  INV_STATUS_ACCEPTED: "Godkänd",

  // Company
  COMPANY_NAME_VALIDATION: "Du måste ange ett kundnamn",
  ORGANIZATION_NUMBER_VALIDATION: "Du måste ange ett organisationsnummer",
  NO_COMPANY_FOUND: "Det finns ingen sådan kund",
  GO_TO_COMPANIES_PAGE: "Gå till kunder",
  SEARCH_COMPANIES: "Sök kunder",
  NO_COMPANIES: "Det finns inga kunder",
  LOGO: "Logotyp",
  ORGANIZATION_NUMBER: "Organisationsnummer",
  NEW_COMPANY: "Ny kund",
  COMPANY_DETAIL: "Detaljer",
  COMPANY_IMAGE: "Kundbild",
  ACTIVATE_COMPANY: "Aktivera",
  ORGANIZATION_NUMBER_ERROR_MESSAGE:
    'Ogiltigt organisationsnummerformat. Den ska ha det här mönstret "123456-1234".',
  DUPLICATED_COMPANY:
    "Vi har redan ett företag med detta namn eller företagsnummer, vill du redigera det?",

  // Department
  DEPARTMENT_NAME_VALIDATION: "Du måste ange ett namn",
  NO_DEPARTMENT_FOUND: "Det finns ingen sådan avdelning",
  GO_TO_DEPARTMENTS_PAGE: "Gå till avdelningar",
  NEW_DEPARMENT: "Ny avdelning",
  DEPARTMENT_DETAIL: "Detaljer",
  SEARCH_DEPARTMENTS: "Sök avdelningar",
  NO_DEPARTMENTS: "Det finns inga avdelningar",

  // Reseller
  RESELLER_NAME_VALIDATION: "Du måste ange ett namn",
  NO_RESELLER_FOUND: "Det finns ingen sådant företagscenter",
  GO_TO_RESELLERS_PAGE: "Gå till företagscenter",
  NEW_RESELLER: "Nytt företagscenter",
  RESELLER_DETAIL: "Detaljer",
  BRANCH_NUMBER: "Filialnummer",
  SEARCH_RESELLERS: "Sök företagscenter",
  NO_RESELLERS_FOUND: "Det finns inga företagscenter",

  // Roles
  ADMIN: "Systemadministratör Dialect",
  RESELLER: "Administratör Dialect",
  COMPANYMANAGER: "Företagsadministratör",
  DEPARTMENTMANAGER: "Avdelningsadministratör",
  EMPLOYEE: "Anställd",
  CUSTOMER: "Kund",

  // Settings
  LOADING: "Laddar",

  // Generic Messages
  ERROR_GENERIC: "Ett fel har uppstått, försök igen senare",
  SUCCESS_GENERIC: "Lyckades",

  // Auth Messages
  SIGN_IN_SUCCESS: "Inloggad",
  SIGN_IN_ERROR: "Fel användarnamn eller lösenord",
  SIGN_OUT_SUCCESS: "Utloggad",
  SIGN_OUT_ERROR: "Ett fel har uppstått, försök igen senare",

  TEMP_DISABLED: "Tillfälligt avaktiverad",
};

export default locale;
