import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "app/store/userSlice";
import { getCompany } from "app/store/companySwitcherSlice";
import { useEffect, useState } from "react";

const Root = styled("div")(({ theme }) => ({
  "& .username, & .email": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  "& .avatar": {
    background: theme.palette.background.default,
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    "& > img": {
      borderRadius: "50%",
    },
  },
}));

function UserNavbarHeader(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [currentCompany, setCurrentCompany] = useState();

  useEffect(() => {
    dispatch(getCompany()).then((action) => {
      setCurrentCompany(action?.payload);
    });
  }, []);

  const navigate = useNavigate();
  function handleClickAvatar() {
    let userProfileUrl = `/companyUser/${user.id}`;
    if (user?.role === "Admin") userProfileUrl = `/adminUser/${user.id}`;
    else if (user?.role === "Reseller")
      userProfileUrl = `/resellerUser/${user.id}`;

    navigate(userProfileUrl);
  }

  return (
    <Root className="user relative flex flex-col items-center justify-center p-16 pb-14 shadow-0">
      <div className="flex items-center justify-center mb-24">
        <Avatar
          sx={{
            backgroundColor: "background.paper",
            color: "text.secondary",
          }}
          onClick={() => handleClickAvatar()}
          className="avatar text-32 font-bold w-96 h-96"
          src={user?.imagePath}
          alt={currentCompany?.name}
        >
          {currentCompany?.name}
          {/* {user.data.displayName.charAt(0)} */}
        </Avatar>
      </div>
      <Typography className="username text-14 whitespace-nowrap font-medium">
        {currentCompany?.name}
      </Typography>
      <Typography
        className="email text-13 whitespace-nowrap font-medium"
        color="text.secondary"
      >
        {currentCompany?.companyCode}
      </Typography>
    </Root>
  );
}

export default UserNavbarHeader;
