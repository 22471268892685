const locale = {
  DASHBOARDS: 'Dashboards',
  ASSET_DASHBOARD: 'Tillgångsöversikt',
  ASSETS: 'Hårdvara',
  ADMINISTRATION: 'Administration',
  STATIONS: 'Platser',
  ASSET_CATEGORIES: 'Kategorier',
  INVENTORY_CATEGORIES: 'Artikelkategorier',
  INVENTORY: 'Artiklar',
  USERS: 'Användare',
  COMPANIES: 'Kunder',
  DEPARTMENTS: 'Avdelningar',
  RESELLERS: 'Företagscenter',
  VEHICLES: 'Fordon',
  CUSTOMERADMINISTRATION: 'Kundadministration',
  RESELLERADMINISTRATION: 'Företagscenteradministration',
  ADMINADMINISTRATION: 'Systemadministration',
};

export default locale;
