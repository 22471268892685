import authRoles from '../../auth/authRoles';
import ClassicForgotPasswordPage from './ClassicForgotPasswordPage';

const ForgotPasswordConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.OnlyGuest,
  routes: [
    {
      path: 'forgot-password',
      element: <ClassicForgotPasswordPage />,
    },
  ],
};

export default ForgotPasswordConfig;
