import i18next from 'i18next';
import se from './navigation-i18n/se';
import en from './navigation-i18n/en';

i18next.addResourceBundle('se', 'navigation', se);
i18next.addResourceBundle('en', 'navigation', en);

const navigationConfig = [
  {
    id: 'dashboards',
    title: 'Dashboards',
    type: 'group',
    icon: 'heroicons-outline:home',
    translate: 'DASHBOARDS',
    auth: ['Admin', 'CompanyManager', 'DepartmentManager', 'Reseller'],
    children: [
      {
        id: 'dashboards.analytics',
        title: 'asdasd',
        translate: 'ASSET_DASHBOARD',
        type: 'item',
        icon: 'heroicons-outline:chart-pie',
        url: '/dashboards/analytics',
        auth: ['Admin', 'CompanyManager', 'DepartmentManager', 'Reseller'],
      },
    ],
  },
  {
    id: 'customer.administration',
    title: 'CustomerAdministration',
    type: 'group',
    icon: 'heroicons-outline:home',
    translate: 'CUSTOMERADMINISTRATION',
    auth: [
      'Admin',
      'CompanyManager',
      'DepartmentManager',
      'Reseller',
      'Employee',
    ],
    children: [
      {
        id: 'assets',
        title: 'Assets',
        type: 'item',
        translate: 'ASSETS',
        icon: 'developer_board',
        url: '/asset',
        auth: [
          'Admin',
          'CompanyManager',
          'DepartmentManager',
          'Reseller',
          'Employee',
        ],
      },
      {
        id: 'asset-categories',
        title: 'Asset Categories',
        translate: 'ASSET_CATEGORIES',
        type: 'item',
        icon: 'heroicons-outline:view-list',
        url: '/asset-categories',
        auth: ['Admin', 'CompanyManager', 'DepartmentManager', 'Reseller'],
      },
      {
        id: 'departments',
        title: 'Departments',
        translate: 'DEPARTMENTS',
        type: 'item',
        icon: 'widgets',
        url: '/departments',
        auth: ['Admin', 'CompanyManager', 'DepartmentManager', 'Reseller'],
      },
      {
        id: 'stations',
        title: 'Stations',
        translate: 'STATIONS',
        type: 'item',
        icon: 'heroicons-outline:cube',
        url: '/stations',
        auth: ['Admin', 'CompanyManager', 'DepartmentManager', 'Reseller'],
      },
      {
        id: 'vehicles',
        title: 'Vehicles',
        translate: 'VEHICLES',
        type: 'item',
        icon: 'heroicons-outline:truck',
        url: '/vehicles',
        auth: ['Admin', 'CompanyManager', 'DepartmentManager', 'Reseller'],
      },
      {
        id: 'users',
        title: 'Users',
        translate: 'USERS',
        type: 'item',
        icon: 'heroicons-outline:user-group',
        url: '/companyUsers',
        auth: [
          'Admin',
          'CompanyManager',
          'DepartmentManager',
          'Reseller',
          'Employee',
        ],
      },
    ],
  },
  {
    id: 'reseller.administration',
    title: 'ResellerAdministration',
    type: 'group',
    icon: 'heroicons-outline:home',
    translate: 'RESELLERADMINISTRATION',
    auth: ['Admin', 'Reseller'],
    children: [
      {
        id: 'companies',
        title: 'Companies',
        translate: 'COMPANIES',
        type: 'item',
        icon: 'heroicons-outline:office-building',
        url: '/companies',
      },
      {
        id: 'users',
        title: 'Users',
        translate: 'USERS',
        type: 'item',
        icon: 'heroicons-outline:user-group',
        url: '/resellerUsers',
      },
    ],
  },
  {
    id: 'admin.administration',
    title: 'AdminAdministration',
    type: 'group',
    icon: 'heroicons-outline:home',
    translate: 'ADMINADMINISTRATION',
    auth: ['Admin'],
    children: [
      {
        id: 'resellers',
        title: 'Resellers',
        translate: 'RESELLERS',
        type: 'item',
        icon: 'heroicons-outline:currency-dollar',
        url: '/resellers',
      },
      {
        id: 'users',
        title: 'Users',
        translate: 'USERS',
        type: 'item',
        icon: 'heroicons-outline:user-group',
        url: '/adminUsers',
      },
    ],
  },
];

export default navigationConfig;
