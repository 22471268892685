import { ThemeProvider } from '@mui/material/styles';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFuseCurrentLayoutConfig, selectNavbarTheme } from 'app/store/fuse/settingsSlice';
import { selectFuseNavbar } from 'app/store/fuse/navbarSlice';
import NavbarStyle1 from './navbar/style-1/NavbarStyle1';
import NavbarStyle2 from './navbar/style-2/NavbarStyle2';
import NavbarStyle3 from './navbar/style-3/NavbarStyle3';
import NavbarToggleFab from '../../shared-components/NavbarToggleFab';

function NavbarWrapperLayout1({ companyThemeConfig, isLoading }) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const navbarTheme = useSelector(selectNavbarTheme);
  const [companyTheme, setCompanyTheme] = useState(navbarTheme);

  useEffect(() => {
    if (!navbarTheme || Object.keys(companyThemeConfig).length === 0) return;
    const { backgroundHex, textPrimaryRgb, textSecondaryRgb, textDisabledRgb } = companyThemeConfig;

    const updatedTheme = {
      ...navbarTheme,
      palette: {
        ...navbarTheme.palette,
        background: {
          default: backgroundHex,
          paper: backgroundHex,
        },
        text: {
          primary: textPrimaryRgb,
          secondary: textSecondaryRgb,
          disabled: textDisabledRgb,
        },
        secondary: {
          ...navbarTheme.palette.secondary,
          light: textSecondaryRgb,
        },
      },
    };
    setCompanyTheme(updatedTheme);
  }, [companyThemeConfig]);

  return (
    companyTheme &&
    !isLoading && (
      <>
        <ThemeProvider theme={companyTheme}>
          <>
            {config.navbar.style === 'style-1' && <NavbarStyle1 />}
            {config.navbar.style === 'style-2' && <NavbarStyle2 />}
            {config.navbar.style === 'style-3' && <NavbarStyle3 />}
            {config.navbar.style === 'style-3-dense' && <NavbarStyle3 dense />}
          </>
        </ThemeProvider>
        {config.navbar.display && !config.toolbar.display && !navbar.open && <NavbarToggleFab />}
      </>
    )
  );
}

export default memo(NavbarWrapperLayout1);
