import FuseDialog from '@fuse/core/FuseDialog';
import { styled } from '@mui/material/styles';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import AppContext from 'app/AppContext';
import { memo, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { fetchCompanies } from 'app/store/companySwitcherSlice';
import { getCompanyTheme, selectCompanyTheme } from 'app/store/companyThemeSlice';
import { selectFuseCurrentLayoutConfig } from 'app/store/fuse/settingsSlice';
import { selectUser as selectLoggedUser } from 'app/store/userSlice';
import Box from '@mui/material/Box';
import FuseLoading from '@fuse/core/FuseLoading';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';

const Root = styled('div')(({ theme, config }) => ({
  ...(config.mode === 'boxed' && {
    clipPath: 'inset(0)',
    maxWidth: `${config.containerWidth}px`,
    margin: '0 auto',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  ...(config.mode === 'container' && {
    '& .container': {
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
    },
  }),
}));

function Layout1(props) {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const isFilterLoading = useSelector((state) => state.companySwitcher.loading);
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const { routes } = appContext;
  const loggedUser = useSelector(selectLoggedUser);
  const selectedCompany = useSelector((state) => state.companySwitcher.selectedCompany);
  const companyThemeConfig = useSelector(selectCompanyTheme);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (loggedUser.role.length > 0) dispatch(fetchCompanies());
  }, [dispatch, loggedUser.role.length]);

  useEffect(() => {
    if (loggedUser.role.length > 0 && selectedCompany != null)
      dispatch(getCompanyTheme(selectedCompany)).then(() => setIsLoading(false));

    else setIsLoading(false);
  }, [dispatch, selectedCompany]);

  return (
    <Root id="fuse-layout" config={config} className="w-full flex">
      {config.leftSidePanel.display && <LeftSideLayout1 />}

      <div className="flex flex-auto min-w-0">
        {config.navbar.display && config.navbar.position === 'left' && (
          <NavbarWrapperLayout1 companyThemeConfig={companyThemeConfig} isLoading={isLoading} />
        )}
        <main id="fuse-main" className="flex flex-col flex-auto min-h-full min-w-0 relative z-10">
          {config.toolbar.display && (
            <ToolbarLayout1
              className={config.toolbar.style === 'fixed' && 'sticky top-0'}
              companyThemeConfig={companyThemeConfig}
              isLoading={isLoading}
            />
          )}
          {isFilterLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
              <FuseLoading />
            </Box>
          ) : (
            <>
              <div className="flex flex-col flex-auto min-h-0 relative z-10">
                <FuseDialog />

                <FuseSuspense>{useRoutes(routes)}</FuseSuspense>

                {props.children}
              </div>

              {config.footer.display && (
                <FooterLayout1
                  className={config.footer.style === 'fixed' && 'sticky bottom-0'}
                  companyThemeConfig={companyThemeConfig}
                  isLoading={isLoading}
                />
              )}
            </>
          )}
        </main>

        {config.navbar.display && config.navbar.position === 'right' && (
          <NavbarWrapperLayout1 companyThemeConfig={companyThemeConfig} isLoading={isLoading} />
        )}
      </div>

      {config.rightSidePanel.display && <RightSideLayout1 />}
      <FuseMessage />
    </Root>
  );
}

export default memo(Layout1);
