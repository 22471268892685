/* eslint-disable no-sparse-arrays */
import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import ActivateConfig from '../main/activate/ActivateConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import ForgotPasswordConfig from '../main/forgot-password/ForgotPasswordConfig';
import ResetPasswordConfig from '../main/reset-password/ResetPasswordConfig';
import ConfirmationRequiredConfig from '../main/email-confirmation-forgot-password/ConfirmationRequiredConfig';
import Error404Page from '../main/404/Error404Page';
import AssetsAppConfig from '../main/apps/e-commerce/ECommerceAppConfig';
import dashboardsConfigs from '../main/dashboards/dashboardsConfigs';

const routeConfigs = [
  AssetsAppConfig,
  SignOutConfig,
  SignInConfig,
  ActivateConfig,
  SignUpConfig,
  ForgotPasswordConfig,
  ResetPasswordConfig,
  ConfirmationRequiredConfig,
  ...dashboardsConfigs,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: '/',
    element: <Navigate to="dashboards/analytics" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: '/users',
    element: <Navigate to="/apps/contacts" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    id: 'assets',
    path: '/asset',
    element: <Navigate to="/apps/assets" />,
    auth: AssetsAppConfig.defaultAuth,
    children: [
      {
        id: 'asset-detail',
        title: 'Asset Detail',
        type: 'item',
        url: 'apps/assets/:assetId/:assetSku',
      },
      {
        id: 'asset-new-asset',
        title: 'New Asset',
        type: 'item',
        url: 'apps/assets/new',
      },
    ],
  },
  {
    id: 'asset-categories',
    path: '/asset-categories',
    element: <Navigate to="/apps/asset-categories" />,
    auth: AssetsAppConfig.defaultAuth,
    children: [
      {
        id: 'asset-detail',
        title: 'Asset Category Detail',
        type: 'item',
        url: 'apps/asset-categories/:assetCategoryId',
      },
      {
        id: 'asset-categories-new-asset-category',
        title: 'New Asset Category',
        type: 'item',
        url: 'apps/asset-categories/new',
      },
    ],
  },
  {
    id: 'departments',
    path: '/departments',
    element: <Navigate to="/apps/departments" />,
    auth: AssetsAppConfig.defaultAuth,
    children: [
      {
        id: 'departments-detail',
        title: 'Department Detail',
        type: 'item',
        url: 'apps/departments/:departmentId/',
      },
      {
        id: 'departments-new-department',
        title: 'New Department',
        type: 'item',
        url: 'apps/departments/new',
      },
    ],
  },
  {
    id: 'companies',
    path: '/companies',
    element: <Navigate to="/apps/companies" />,
    auth: AssetsAppConfig.defaultAuth,
    children: [
      {
        id: 'companies-detail',
        title: 'Company Detail',
        type: 'item',
        url: 'apps/companies/:companyId/',
      },
      {
        id: 'companies-new-company',
        title: 'New Company',
        type: 'item',
        url: 'apps/companies/new',
      },
    ],
  },
  {
    id: 'stations',
    path: '/stations',
    element: <Navigate to="/apps/stations" />,
    auth: AssetsAppConfig.defaultAuth,
    children: [
      {
        id: 'stations-detail',
        title: 'Station Detail',
        type: 'item',
        url: 'apps/stations/:stationId/',
      },
      {
        id: 'stations-new-station',
        title: 'New Station',
        type: 'item',
        url: 'apps/stations/new',
      },
    ],
  },
  {
    id: 'vehicles',
    path: '/vehicles',
    element: <Navigate to="/apps/vehicles" />,
    auth: AssetsAppConfig.defaultAuth,
    children: [
      {
        id: 'vehicles-detail',
        title: 'Vehicles Detail',
        type: 'item',
        url: 'apps/vehicles/:vehicleId/',
      },
      {
        id: 'vehicles-new-vehicle',
        title: 'New Vehicles',
        type: 'item',
        url: 'apps/vehicles/new',
      },
    ],
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  },
];

export default routes;
