import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import jwtDecode from "jwt-decode";
import jwtServiceConfig from "./jwtServiceConfig";

/* eslint-disable camelcase */
const timeToRefresh = 1000 * 60 * 15;

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
            // Clear the refresh timer
            if (this.refreshTimer) clearInterval(this.refreshTimer);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    const refresh_token = this.getRenewToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token, refresh_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit("onLogin", response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, { username: email, password })
        .then((response) => {
          if (response.status === 200) {
            const { accessToken, refreshToken } = response.data.token;
            this.setSession(accessToken, refreshToken);

            if (response.data.user.hasOwnProperty("companyId")) {
              localStorage.setItem(
                "selectedCompany",
                response.data.user.companyId
              );
            }

            response.data.user.role = response.data.user.userRoles[0]; // 'CompanyManager';
            response.data.user.data = {};
            response.data.user.firstName = response.data.user.userName;
            response.data.user.lastName = "";
            response.data.user.data.displayName = response.data.user.userName;
            response.data.user.imagePath =
              response.data.user.imagePath !== ""
                ? response.data.user.imagePath
                : "assets/images/avatars/default-profile-picture.jpg";
            response.data.user.data.settings = {
              layout: {},
              theme: {},
            };
            // response.data.user.data.shortcuts = ['apps.calendar', 'apps.mailbox', 'apps.contacts'];
            localStorage.setItem("user", JSON.stringify(response.data.user));
            resolve(response.data.user);
            this.emit("onLogin", response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(new Error("Email or Password is incorrect."));
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      if (this.isAuthTokenValid(this.getAccessToken())) {
        resolve(JSON.parse(localStorage.getItem("user")));
      }
      reject(new Error("Failed to login with token"));
    });
    /* return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.accessToken, {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    }); */
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token, refresh_token) => {
    if (access_token) {
      // Store AccessToken
      localStorage.setItem("jwt_access_token", access_token);
      localStorage.setItem("jwt_refresh_token", refresh_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;

      // Store RenewToken

      // Start a timer that will automatically refresh the token in 15min
      if (this.refreshTimer) clearInterval(this.refreshTimer);

      this.refreshTimer = setInterval(() => {
        this.refreshAccessToken();
      }, timeToRefresh);
    } else {
      localStorage.removeItem("jwt_access_token");
      localStorage.removeItem("jwt_refresh_token");
      localStorage.removeItem("user");
      localStorage.removeItem("selectedCompany");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    axios
      .post(jwtServiceConfig.signOut, {})
      .then((response) => {
        if (response.status !== 200) {
          // Log the error in a third party service for log review
        }
        this.emit("onLogout", "Logged out");
        this.setSession(null);
        localStorage.setItem("openWarningMessage", true);
        // Clear the refresh timer
        if (this.refreshTimer) clearInterval(this.refreshTimer);
      })
      .catch((error) => {
        new Error(
          "Unable to complete the Logout process correctly. Check logs."
        );
      });
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.expires < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };

  getRenewToken = () => {
    return window.localStorage.getItem("jwt_refresh_token");
  };

  refreshAccessToken = () => {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRenewToken();

    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.refreshAccessToken, {
          accessToken,
          refreshToken,
        })
        .then((response) => {
          if (response.data.accessToken && response.data.refreshToken) {
            const { accessToken, refreshToken } = response.data;
            this.setSession(accessToken, refreshToken);
            resolve(accessToken);
          } else {
            this.logout();
            reject(new Error("Failed to refresh access token."));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to refresh access token."));
        });
    });
  };
}

const instance = new JwtService();

export default instance;
