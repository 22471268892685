const midnightBlue = {
  50: '#e8edef',
  100: '#d1dbe0',
  200: '#bac9d0',
  300: '#a3b8c1',
  400: '#8ca6b1',
  500: '#7594a2',
  600: '#5e8392',
  700: '#477183',
  800: '#305f73',
  900: '#1A4E64',
  contrastDefaultColor: 'light',
};

export default midnightBlue;
