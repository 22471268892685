const locale = {
  DASHBOARDS: 'Dashboards',
  ASSETS: 'Hardware',
  ASSET_DASHBOARD: 'Asset Dashboard',
  ADMINISTRATION: 'Administration',
  STATIONS: 'Places',
  ASSET_CATEGORIES: 'Categories',
  INVENTORY_CATEGORIES: 'Inventory Categories',
  INVENTORY: 'Inventory',
  USERS: 'Users',
  COMPANIES: 'Customers',
  DEPARTMENTS: 'Departments',
  RESELLERS: 'Resellers',
  VEHICLES: 'Vehicles',
  CUSTOMERADMINISTRATION: 'Customer Administration',
  RESELLERADMINISTRATION: 'Reseller Administration',
  ADMINADMINISTRATION: 'System Administration',
};

export default locale;
