import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  selectedCompany: null,
  companies: [],
  loading: false,
  error: null,
};

export const fetchCompanies = createAsyncThunk('companySwitcher/fetchCompanies', async (undefined, { dispatch, getState }) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    let filter = ''; 
    if(user.role === 'Reseller'){
      filter += `?resellerId=${user.resellerId}`;
    } 
    const url = `/company${(filter.length > 0) ? filter : ''}`
    
    const response = await axios.get(url);
    let localStorageCompany = localStorage.getItem('selectedCompany');
    const activeCompanies = response.data.filter((_item) => _item.isActive);

    if(localStorageCompany === undefined || localStorageCompany === '' || localStorageCompany === null){
      localStorageCompany = activeCompanies[0].id;
    }
    dispatch(selectCompany(localStorageCompany));
    return activeCompanies;
  } catch (error) {
    throw Error('Failed to fetch companies');
  }
});

export const getCompany = createAsyncThunk('companySwitcher/getCompany', async () => {
  try {
    const response = await axios.get('/company/' + localStorageCompany);
    return response.data;
  } catch (error) {
    throw Error('Failed to fetch companies');
  }
});

const companySwitcherSlice = createSlice({
  name: 'companySwitcher',
  initialState,
  reducers: {
    selectCompany(state, action) {
      state.selectedCompany = action.payload;
      localStorage.setItem('selectedCompany', action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.companies = action.payload;

        if (!state.selectedCompany && action.payload.length > 0) {
          state.selectedCompany = action.payload[0].id;
          localStorage.setItem('selectedCompany', action.payload[0].id);
        }
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { selectCompany } = companySwitcherSlice.actions;
export default companySwitcherSlice.reducer;
