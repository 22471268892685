import '@mock-api';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import {
  getCompanyTheme,
  selectCompanyTheme,
} from 'app/store/companyThemeSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import { selectUser as selectLoggedUser } from 'app/store/userSlice';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import settingsConfig from 'app/configs/settingsConfig';
import axios from 'axios';
import withAppProviders from './withAppProviders';
import { AuthProvider } from './auth/AuthContext';

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_API;

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectLoggedUser);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);
  const selectedCompany = useSelector(
    (state) => state.companySwitcher.selectedCompany
  );
  const companyThemeConfig = useSelector(selectCompanyTheme);
  const [companyTheme, setCompanyTheme] = useState(null);

  useEffect(() => {
    if (user.role.length > 0 && selectedCompany != null)
      dispatch(getCompanyTheme(selectedCompany));
  }, [user]);

  useEffect(() => {
    if (Object.keys(companyThemeConfig).length > 0) {
      const {
        backgroundHex,
        textPrimaryRgb,
        textSecondaryRgb,
        textDisabledRgb,
      } = companyThemeConfig;

      const updatedTheme = {
        ...mainTheme,
        palette: {
          ...mainTheme.palette,
          secondary: {
            ...mainTheme.palette.secondary,
            main: backgroundHex,
            dark: textSecondaryRgb,
          },
        },
      };
      setCompanyTheme(updatedTheme);
    }
  }, [companyThemeConfig]);

  useEffect(() => {
    window.$sleek = [];
    window.SLEEK_PRODUCT_ID = process.env.REACT_APP_SLEEK_PRODUCT_ID;
    (function () {
      var d = document;
      var s = d.createElement('script');
      s.src = 'https://client.sleekplan.com/sdk/e.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }, []);

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <FuseTheme theme={companyTheme || mainTheme} direction={langDirection}>
        <AuthProvider>
          <BrowserRouter>
            <FuseAuthorization
              userRole={user.role}
              loginRedirectUrl={settingsConfig.loginRedirectUrl}
            >
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  containerRoot:
                    'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                }}
              >
                <FuseLayout layouts={themeLayouts} />
              </SnackbarProvider>
            </FuseAuthorization>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </CacheProvider>
  );
};

export default withAppProviders(App)();
