import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { selectFuseNavbar } from 'app/store/fuse/navbarSlice';
import { selectFuseCurrentLayoutConfig, selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import AdjustFontSize from '../../shared-components/AdjustFontSize';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import NavigationShortcuts from '../../shared-components/NavigationShortcuts';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';
import NavbarToggleButton from '../../shared-components/NavbarToggleButton';
import CompanySwitcher from '../../shared-components/CompanySwitcher';
import UserMenu from '../../shared-components/UserMenu';

function ToolbarLayout1(props) {
  const { companyThemeConfig, isLoading } = props;
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const isMobile = useThemeMediaQuery((_theme) => _theme.breakpoints.down('lg'));
  const bugReportIconSize = isMobile ? 20 : 32;
  const [bugReportClass, setBugReportClass] = useState('');
  const [companyTheme, setCompanyTheme] = useState(toolbarTheme);

  useEffect(() => {
    if (!toolbarTheme || Object.keys(companyThemeConfig).length === 0) return;

    const { backgroundHex, textPrimaryRgb, textSecondaryRgb, textDisabledRgb } = companyThemeConfig;

    const updatedTheme = {
      ...toolbarTheme,
      palette: {
        ...toolbarTheme.palette,
        background: {
          default: backgroundHex,
          paper: backgroundHex,
        },
        text: {
          primary: textPrimaryRgb,
          secondary: textSecondaryRgb,
          disabled: textDisabledRgb,
        },
      },
    };
    setCompanyTheme(updatedTheme);
  }, [companyThemeConfig]);

  useEffect(() => {
    if (isMobile && navbar.open) {
      setBugReportClass('flex-shrink-0 absolute left-0 ml-48');
    } else if (isMobile && !navbar.open) {
      setBugReportClass('flex-shrink-0 absolute left-0 ml-24');
    } else if (!isMobile && !navbar.open) {
      setBugReportClass('flex-shrink-0 absolute left-0 ml-52');
    } else {
      setBugReportClass('flex-shrink-0 absolute left-0');
    }
  }, [navbar.open, isMobile]);

  return (
    companyTheme &&
    !isLoading && (
      <ThemeProvider theme={companyTheme}>
        <AppBar
          id="fuse-toolbar"
          className={clsx('flex relative z-20 shadow-md', props.className)}
          color="default"
          sx={{
            backgroundColor: (theme) =>
              companyTheme.palette.mode === 'light'
                ? companyTheme.palette.background.paper
                : companyTheme.palette.background.default,
          }}
          position="static"
        >
          <Toolbar className="p-0 min-h-48 md:min-h-64">
            <div className="flex flex-1 px-16">
              {config.navbar.display && config.navbar.position === 'left' && (
                <>
                  <Hidden lgDown>
                    {(config.navbar.style === 'style-3' ||
                      config.navbar.style === 'style-3-dense') && (
                      <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                    )}

                    {config.navbar.style === 'style-1' && !navbar.open && (
                      <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                    )}
                  </Hidden>

                  <Hidden lgUp>
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                  </Hidden>
                </>
              )}

              <Hidden lgDown>
                <NavigationShortcuts />
              </Hidden>
            </div>

            <div className="flex items-center px-8 h-full overflow-x-auto">
              <CompanySwitcher />

              <LanguageSwitcher />

              <AdjustFontSize />

              <FullScreenToggle />

              {/* <NavigationSearch />

              <Hidden lgUp>
                <ChatPanelToggleButton />
              </Hidden> 

              <QuickPanelToggleButton /> 

              <NotificationPanelToggleButton /> */}

              <UserMenu />
            </div>

            {config.navbar.display && config.navbar.position === 'right' && (
              <>
                <Hidden lgDown>
                  {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    )
  );
}

export default memo(ToolbarLayout1);
