const jwtServiceConfig = {
  signIn: '/auth/login',
  signUp: '/auth/sign-up',
  signOut: '/auth/logout',
  forgotPassword: '/auth/forgotPassword',
  resetPassword: '/auth/resetPassword',
  refreshAccessToken: '/auth/refreshToken',
  accessToken: '',
  updateUser: '',
};

export default jwtServiceConfig;
