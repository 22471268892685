import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { resetPassword } from '../../store/userSlice';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/,
      'Please make sure to include at least one uppercase letter, one lowercase letter, one special character, one number, and have a minimum length of 8 characters.'
    )
    .required('Please enter your password.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const defaultValues = {
  password: '',
  confirmPassword: '',
};

function ClassicResetPasswordPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { control, formState, handleSubmit, reset, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (!email || !token) navigate('/sign-in');
  }, [email, token, navigate]);

  function onSubmit() {
    setLoading(true);
    const rPassword = {
      ...getValues(),
      email,
      token,
    };
    dispatch(resetPassword(rPassword)).then(({ payload }) => {
      if (payload == '') {
        dispatch(
          showMessage({
            message: 'The password was changed',
            autoHideDuration: 3000,
            variant: 'success',
          })
        );
        navigate('/sign-in');
      } else {
        dispatch(
          showMessage({
            message: payload.detail,
            autoHideDuration: 3000,
            variant: 'error',
          })
        );
      }
      setLoading(false);
    });
  }

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img
            className="img-fluid"
            src="assets/images/logo/logo.png"
            style={{ maxWidth: '200px' }}
            alt="logo"
          />
          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Reset your password
          </Typography>
          <Typography className="font-medium">Create a new password for your account</Typography>

          <form
            name="registerForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  label="Password (Confirm)"
                  type="password"
                  error={!!errors.confirmPassword}
                  helperText={errors?.confirmPassword?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
            {loading ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                className=" w-full mt-4"
                aria-label="Register"
                disabled={_.isEmpty(dirtyFields) || !isValid}
                type="submit"
                size="large"
              >
                Reset your password
              </Button>
            )}

            <Typography className="mt-32 text-md font-medium" color="text.secondary">
              <span>Return to</span>
              <Link className="ml-4" to="/sign-in">
                sign in
              </Link>
            </Typography>
          </form>
        </div>
      </Paper>
    </div>
  );
}

export default ClassicResetPasswordPage;
