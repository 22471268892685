import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import API from '../main/apps/e-commerce/CompanyThemeConfig';

export const getCompanyTheme = createAsyncThunk('getCompanyTheme', async () => {
  const response = await axios.get(API.getById);
  const data = await response.data;
  return data;
});

const companyThemeSlice = createSlice({
  name: 'companyTheme',
  initialState: {},
  extraReducers: {
    [getCompanyTheme.fulfilled]: (state, action) => action.payload,
  },
});

export const selectCompanyTheme = ({ companyTheme }) => companyTheme;

export default companyThemeSlice.reducer;
