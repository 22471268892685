const locale = {
  // Page Headers
  ASSETS: "Hardware",
  ASSET: "Hardware",
  ASSET_CATEGORIES: "Categories",
  ASSET_CATEGORY: "Category",
  ASSET_DASHBOARD: "Asset Dashboard",
  STATIONS: "Places",
  STATION: "Place",
  USER: "User",
  COMPANYUSERS: "Users",
  RESELLERUSERS: "Administrators Dialect",
  ADMINUSERS: "Administrators",
  COMPANYUSER: "User",
  RESELLERUSER: "Administrator Dialect",
  ADMINUSER: "Administrator",
  COMPANIES: "Customers",
  COMPANY: "Customer",
  DEPARTMENTS: "Departments",
  DEPARTMENT: "Department",
  RESELLERS: "Resellers",
  RESELLER: "Reseller",
  INVENTORY_CATEGORIES: "Inventory Categories",
  INVENTORY_CATEGORY: "Inventory Category",

  // Tab Headers
  BASIC_INFO: "Information",
  ASSET_IMAGES: "Images",
  ASSET_ATTACHMENTS: "Attachments",
  ASSET_ASSIGN: "Assign",
  ASSET_ASSIGN_HISTORY: "History",

  // Shared
  ADD: "Add",
  NAME: "Name",
  DETAILS: "Details",
  ROWS_PER_PAGE: "Rows per page",
  REMOVE: "Remove",
  SAVE: "Save",
  CHOOSE: "Choose",
  ASSIGN: "Assign",
  ASSIGN_TYPE: "Assign Type",
  SUCCESS_SAVE: "Saved",
  ERROR_SAVE: "An error has occured, please try again later",
  CREATED: "Created",
  UPDATED: "Updated",
  ACTIVE: "Active",
  DEPARTMENT_IS_REQUIRED: "Department is required",
  CLEAR_FILTERS: "Clear filters",
  ASSIGN_TO_USER: "Assign to user",
  REASSIGN_TO_USER: "Re-assign asset",
  ASSIGN_TO_STATION: "Assign to place",
  ASSIGN_TO_VEHICLE: "Assign to vehicle",
  PAGE: "Page",
  OF: "of",
  ALL_OPTIONS: "All",
  UNASSIGN: "Unassign",
  UNASSIGNED: "Unassigned",
  TOTAL: "Total",
  NAME_REQUIRED: "Name is required",
  CATEGORY_REQUIRED: "Category is required",
  PURCHASE_DATE_REQUIRED: "Purchase date is required",
  MISSING_REQUIRED_FIELDS:
    "Missing required fields. Check fields marked with * ( Name, category )",

  // Dashboard
  ASSET_DASHBOARD_ZERO_VAL: "Show zero values",
  ASSET_DASHBOARD_OVERVIEW: "Assets Overview",
  ASSET_DASHBOARD_CATEGORY: "Assets by category",
  ASSET_DASHBOARD_PIECES: " pcs",
  ASSET_DASHBOARD_TOTAL_ASSETS: "Total Assets",
  ASSET_DASHBOARD_INUSE_ASSETS: "In-use Assets",
  ASSET_DASHBOARD_AVAL_ASSETS: "Available Assets",

  // Assets
  SEARCH_TITLE_ASSETS: "Search hardware",
  NO_ASSETS: "There are no hardware items",
  SAVE_ASSET_BEFORE_ASSIGN: "Save the unit before assigning it",
  ASSET_DETAIL: "Details",
  INSTALLATION_IMAGE: "Installation Image",
  ASSET_IMAGE: "Product Image",
  ARTICLE_NUMBER: "Article Number",
  PURCHASE_DATE: "Purchase Date",
  EXPIRE_DATE: "Expiry Date",
  ALTERNATE_DESCRIPTION: "Description",
  CATEGORY: "Category",
  ASSIGNEE: "Assignee",
  PRICE: "Price",
  IMEI: "IMEI Number",
  SERIAL_NUMBER: "Serial Number",
  SERIALIMEI_NUMBER: "Serial/IMEI-number",
  NODE_NUMBER: "Node Number",
  AVAILABLE: "Available",
  ASSIGN_TYPE_REQUIRED: "Assign type is required",
  USER_REQUIRED: "User is required",
  STATION_REQUIRED: "Place is required",
  VEHICLE_REQUIRED: "Vehicle is required",
  GO_TO_ASSETS_PAGE: "Go to hardware",
  ASSIGNED_TO: "Assigned to",
  ASSIGNED_DATE: "Assigned Date",
  ASSIGN_USER_STATION: "Assign to user or place",
  ESTIMETED_TRADEIN: "Estimated Trade In Value",
  SUPPORT_LINK: "Support Link",
  STATUS: "Status",
  PUBLIC_NOTES: "Public Notes",
  ADMIN_NOTES: "Administrator Notes",
  NEW_ASSET: "New hardware",
  ASSET_ASSIGN_MSG_CONFIRMATION:
    "Are you sure you want to assign the unit {{assetName}}?",
  ASSET_UNASSIGN_MSG_CONFIRMATION:
    "Are you sure you want to unassign the unit {{assetName}}?",
  ASSETS_ASSIGN_MSG_CONFIRMATION:
    "Are you sure you want to assign those units?",
  ASSIGN_TO_THIS_USER: "Assign new unit to this user",
  ASSIGN_ASSET_USER: "Assign a unit to user",
  ASSIGN_TO_THIS_STATION: "Assign new unit to this place",
  ASSIGN_TO_THIS_VEHICLE: "Assign new unit to this vehicle",
  ASSIGN_ASSET_STATION: "Assign a unit to a place",
  ASSIGN_ASSET_VEHICLE: "Assign a unit to a vehicle",
  ASSET_NOT_FOUND: "Please save the unit before assigning",
  ASSET_NOT_SAVED_ATTACHMENTS:
    "Please save the unit before upload an attachment",
  ASSET_REMOVE_MSG_CONFIRMATION: "Are you sure you want to remove this unit?",
  ASSET_WITH_NO_ATTACHMENTS: "There are no attachments",
  ASSET_DUPLICATED_ATTACHMENT:
    "The unit already has an attachment with the same name",

  // Asset history
  ASSET_ASSIGN_HISTORY_NO_HISTORY: "No history for this unit.",
  ASSET_HISTORY_CREATE: "Unit created",
  ASSET_HISTORY_UPDATE: "Unit updated",
  ASSET_HISTORY_DELETE: "Unit deleted",
  ASSET_HISTORY_ASSIGN: "Unit assigned to ",
  ASSET_HISTORY_UNASSIGN: "Unit unassigned ",
  ASSET_HISTORY_ASSETIMAGE: "Product image updated",
  ASSET_HISTORY_ASSETISNTIMAGE: "Installation image updated",
  ASSET_HISTORY_BY: "by",
  ASSET_HISTORY_AT: "at",

  // Asset Status
  IN_USE: "In Use",
  UNDER_REPAIR: "Under Repair",
  FREE: "Free",

  // Station
  NEW_STATION: "New place",
  STATION_DETAIL: "Details",
  DEPARTMENT_SELECT_VALIDATION: "Department is required",
  NO_STATION_FOUND: "Place not found",
  GO_TO_STATION_PAGE: "Go to places",
  STATION_NAME_VALIDATION: "Place name is required",
  SEARCH_STATIONS: "Search places",
  NO_STATIONS_FOUND: "There are no places",
  ASSIGNED_ASSETS_COUNT: "Assigned hardware",

  // Vehicle
  VEHICLES: "Vehicles",
  VEHICLE: "Vehicle",
  NEW_VEHICLE: "New vehicle",
  VEHICLE_DETAIL: "Details",
  SEARCH_VEHICLES: "Search vehicles",
  NO_VEHICLES_FOUND: "There are no Vehicles",
  VEHICLE_NAME_VALIDATION: "Vehicle name is required",
  NO_VEHICLE_FOUND: "Vehicle not found",
  GO_TO_VEHICLE_PAGE: "Go to vehicles",
  VEHICLE_TYPE: "Vehicle Type",
  VEHICLE_MODEL: "Vehicle Model",
  CAR: "Car",
  MOTORCYCLE: "Motorcycle",
  TRUCK: "Truck",
  BUS: "Bus",
  BICYCLE: "Bicycle",

  // Asset Categories
  SEARCH_ASSETS_CATEGORIES: "Search categories",
  NO_ASSETS_CATEGORIES: "There are no categories",
  CATEGORY_NAME_VALIDATION: "Category name is required",
  NO_ASSET_CATEGORY: "Category not found",
  GO_TO_ASSET_CATEGORY: "Go to categories",
  NEW_ASSET_CATEGORY: "New category",
  ASSET_CATEGORY_DETAIL: "Details",
  IS_REPORT_VISIBLE: "Show in report",
  ARTICLE_TYPE: "Article Type",

  // Inventory Category
  SEARCH_INVENTORY_CATEGORIES: "Search categories",
  NO_INVENTORY_CATEGORIES: "There are no categories",
  NO_INVENTORY_CATEGORY: "Category not found",
  GO_TO_INVENTORY_CATEGORY: "Go to inventory categories",
  INVENTORY_CATEGORY_DETAIL: "Details",
  CALCULATION_TYPE: "Calculation Type",

  // Inventory
  NO_INVENTORY: "Inventoryitem not found",
  GO_TO_INVENTORIES: "Go to Inventory",
  INVENTORY: "Inventory",
  TITLE: "Title",
  SEARCH_INVENTORY: "Search Inventory",
  DESCRIPTION: "Description",
  SUPPLIER: "Supplier",
  BASE_PRICE: "Base Price",
  PURCHASE_PRICE: "Purchase Price",
  STOCK_PRICE: "Stock Price",
  NO_INVENTORIES: "There are no inventory items",

  // User
  USER_NAME_VALIDATION: "User name is required",
  USER_EMAIL_VALIDATION: "Email is required",
  NO_USER_FOUND: "User not found",
  ROLE: "Role",
  GO_TO_USER_PAGE: "Go to users",
  USER_DETAIL: "Details",
  USER_MSG_CONFIRMATION: "Are you sure you want to deactivate this user?",
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
  USERNAME: "Username",
  PHONE_NUMBER: "Phone Number",
  EMAIL: "Email",
  SHOW_ACTIVE_USERS: "Show active users",
  SHOW_INACTIVE_USERS: "Show inactive users",
  SEARCH_USERS: "Search users",
  NO_USERS_FOUND: "There are no users",
  JOB_TITLE: "Job Title",
  COST_CENTER: "Cost Center",
  EMPLOYEE_NUMBER: "  Employee Number",
  DATE_EMPLOYEEMENT_START: "Employment Start Date",
  DATE_EMPLOYEEMENT_END: "Employment End Date",
  INVITE_STATUS: "  Invite Status",
  MY_PROFILE: "My Profile",
  SIGN_OUT: "Sign out",
  NEW_USER: "New user",
  DEACTIVATE: "Deactivate",
  INVITE: "Invite",
  USER_INVITE_CONFIRM_MSG: "Are you sure you want to invite this user?",
  USER_INVITED: "User was invited successfully.",
  USER_ACTIVATED_ERROR: "User activation failed.",
  USER_ACTIVATED_SUCCESS: "User activated successfully.",
  USERS: "Users",

  // Invite Status
  INV_STATUS_NOTSENT: "Not Sent",
  INV_STATUS_PENDING: "Pending",
  INV_STATUS_ACCEPTED: "Accepted",

  // Company
  COMPANY_NAME_VALIDATION: "Name is required",
  ORGANIZATION_NUMBER_VALIDATION: "Organization number is required",
  NO_COMPANY_FOUND: "Customer not found",
  GO_TO_COMPANIES_PAGE: "Go to customers",
  SEARCH_COMPANIES: "Search customers",
  NO_COMPANIES: "There are no customers",
  LOGO: "Logo",
  ORGANIZATION_NUMBER: "Organization Number",
  NEW_COMPANY: "New customer",
  COMPANY_DETAIL: "Details",
  COMPANY_IMAGE: "Customer Image",
  ACTIVATE_COMPANY: "Activate",
  ORGANIZATION_NUMBER_ERROR_MESSAGE:
    'Invalid organization number format. It should have this pattern "123456-1234".',
  DUPLICATED_COMPANY:
    "We already have a company with this name or organization number, do you want to edit it?",

  // Department
  DEPARTMENT_NAME_VALIDATION: "Name is required",
  NO_DEPARTMENT_FOUND: "Department not found",
  GO_TO_DEPARTMENTS_PAGE: "Go to departments",
  NEW_DEPARMENT: "New department",
  DEPARTMENT_DETAIL: "Details",
  SEARCH_DEPARTMENTS: "Search departments",
  NO_DEPARTMENTS: "There are no departments",

  // Reseller
  RESELLER_NAME_VALIDATION: "Name is required",
  NO_RESELLER_FOUND: "Reseller not found",
  GO_TO_RESELLERS_PAGE: "Go to resellers",
  NEW_RESELLER: "New reseller",
  RESELLER_DETAIL: "Details",
  BRANCH_NUMBER: "Branch Number",
  SEARCH_RESELLERS: "Search resellers",
  NO_RESELLERS_FOUND: "There are no resellers",

  // Roles
  ADMIN: "Systemadministrator Dialect",
  RESELLER: "Administrator Dialect",
  COMPANYMANAGER: "Company Manager",
  DEPARTMENTMANAGER: "Department Manager",
  EMPLOYEE: "Employee",
  CUSTOMER: "Customer",

  // Settings
  LOADING: "Loading",

  // Generic Messages
  ERROR_GENERIC: "An error has occured, please try again later",
  SUCCESS_GENERIC: "Success",

  // Auth Messages
  SIGN_IN_SUCCESS: "Signed in successfully",
  SIGN_IN_ERROR: "Invalid username or password",
  SIGN_OUT_SUCCESS: "Signed out successfully",
  SIGN_OUT_ERROR: "An error has occured, please try again later",

  TEMP_DISABLED: "Temporarily disabled",
};

export default locale;
