import ActivatePage from './Activate';
import ActivatedPage from './ActivatedPage';
import authRoles from '../../auth/authRoles';

const ActivateConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.OnlyGuest,
  routes: [
    {
      path: 'activate',
      element: <ActivatePage />,
    },
    {
      path: 'activated',
      element: <ActivatedPage />,
    },
  ],
};

export default ActivateConfig;