import i18next from 'i18next';
import ModernSignInPage from './SignInPage';
import authRoles from '../../auth/authRoles';
import se from './i18n/se';
import en from './i18n/en';

i18next.addResourceBundle('en', 'ecommerce', en);
i18next.addResourceBundle('se', 'ecommerce', se);

const SignInConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.OnlyGuest,
  routes: [
    {
      path: 'sign-in',
      element: <ModernSignInPage />,
    },
  ],
};

export default SignInConfig;
